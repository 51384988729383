<template>
  <div class="container"
       v-loading="loading">
    <!-- <el-card class="register" v-if="!isRegister">
      <el-row type="flex" justify="center" class="title"
        >Please fill in the information</el-row
      >
      <el-form
        ref="registerForm"
        :model="form"
        label-width="80px"
        :rules="formRule"
      >
        <el-form-item label="Name:" prop="username">
          <el-input
            ref="username"
            v-model="form.username"
            size="mini"
            placeholder="Please enter your name"
          ></el-input>
        </el-form-item>
        <el-form-item label="Email:" prop="email">
          <el-input
            ref="email"
            v-model="form.email"
            size="mini"
            placeholder="Please enter your email"
          ></el-input>
        </el-form-item>
        <el-row type="flex" justify="end">
          <el-button size="mini" type="primary" @click="submit">Next</el-button>
        </el-row>
      </el-form>
    </el-card> -->
    <el-card class="videos"
             v-if="isAnswer && isRegister">
      <!-- <div class="content"> -->
      <el-row>
        <el-col :span="8"
                :xs="24"
                :sm="24"
                :md="12"
                :lg="8"
                :xl="6"
                v-for="video in videos"
                :key="`video-${video.id}`">

          <el-card @click="showVideo(video)"
                   class="content-item">
            <el-image :src="`${video.video_url}?x-oss-process=video/snapshot,t_0,f_jpg`"></el-image>
            <div class="bottom">
              <el-tooltip effect="dark"
                          :content="video.description"
                          placement="bottom">
                <span class="content-title">{{ video.title }}</span>
              </el-tooltip>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- </div> -->
      <el-pagination @current-change="changePage"
                     class="pagination"
                     :hide-on-single-page="false"
                     :total="pagination.total"
                     :page-size="pagination.size"
                     :current-page="pagination.current"
                     layout="prev, pager, next">
      </el-pagination>
    </el-card>

    <!-- <el-dialog
      class="form-dialog"
      :close-on-click-modal="false"
      :modal="true"
      :width="700"
      :title="videoConfig.title"
      :v-model="videoVisible"
      @close="closeVideoConfig"
    >
      <video-player v-if="videoConfig.visible" :options="videoOptions" />
    </el-dialog> -->
    <el-dialog custom-class="video-dialog"
               :title="videoTitle"
               v-model="videoVisible"
               width="60%">
      <video-player v-if="videoVisible"
                    :options="videoOptions" />
    </el-dialog>
  </div>
</template>

<script>
import { reactive } from "vue";
import VideoPlayer from "@/components/VideoPlayer";
import { getProjectVideos } from '@/api/project'
export default {
  components: {
    VideoPlayer
  },
  props: {
    projectId: {
      type: Number,
      default () {
        return 0;
      }
    },
  },
  data () {
    return {
      loading: true,
      videoTitle: '',
      videoVisible: false,
      isRegister: false,
      isAnswer: true,
      project: {},
      info: {},
      form: {
        username: '',
        email: '',
      },
      formRule: {
        username: [
          { required: true, trigger: 'blur', message: "Please enter your name!" }
        ],
        email: [
          { required: true, trigger: 'blur', message: "Please enter your email!" },
        ]
      },
      videos: [],
      pagination: {
        total: 0,
        current: 1,
        size: 10,
      },
      redirect: "",
    }
  },
  setup () {
    const videoOptions = reactive({
      controls: true,//用户可以与之交互的控件
      loop: true,//视频一结束就重新开始
      muted: false,//默认情况下将使所有音频静音
      aspectRatio: "16:9",//显示比率
      fullscreen: {
        options: { navigationUI: 'hide' }
      },
      sources: [
        {
          src: '',
          type: "video/mp4"
        }
      ]
    });
    return {
      videoOptions
    }
  },
  inject: ["reload"],
  mounted () {
    this.redirect = this.$route.query.redirect || ""
    this.project.id = this.projectId
    // try {
    //   this.project.id = Base64.decode(this.$route.params.project_id)
    // } catch (err) {
    //   this.$router.push('/')
    // }
    // if (this.project.id.indexOf(routePKey) === 0) {
    //   this.project.id = this.project.id.replace(routePKey, '')
    // } else {
    //   this.$router.push('/')
    // }
    if (this.$store.state.user.token) {
      this.isRegister = true
      this.$store.dispatch('user/getInfo').then(res => {
        this.info = res.data
      }).catch(() => {
        // console.log(err)
        this.isRegister = false
        this.loading = false
      })

      this.fetchData({
        page: this.pagination.current,
        size: this.pagination.size,
      })
    } else {
      this.isRegister = false
      this.loading = false
    }
  },
  methods: {
    fetchData (params = {}) {
      //   console.log(this.$route.params.params)
      params.link = this.$route.params.params
      //   console.log(params)
      getProjectVideos(params).then(res => {
        this.loading = false
        this.videos = res.data.list
        this.pagination.size = res.data.size
        this.pagination.total = res.data.total
        this.pagination.current = res.data.page
      }).catch(err => {
        // console.log(err)
        if (err.code === 401) {
          this.isAnswer = false
          this.loading = false
        }
      })
    },
    changePage (page) {
      this.fetchData({
        page: page,
        size: this.pagination.size
      })
    },
    submit () {
      this.$refs['registerForm'].validate((valid) => {
        if (valid) {
          this.$store.dispatch('user/register', this.form).then(() => {
            // console.log(this.redirect !== "", this.redirect)
            if (this.redirect !== "") {
              this.$router.push(this.redirect)
            } else {
              this.$store.dispatch('user/getInfo').then(() => {
                this.reload()
              })
            }
          })
        } else {
          return false
        }
      })
    },

    showVideo (item) {
      let time = new Date().getTime()
      this.$router.push('/' + this.$route.params.params + "/" + item.id + "?t=" + time)
      this.reload()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.videos {
  position: absolute;
  top: 10%;
  width: 88%;
}
.title {
  font-size: 24px;
  margin: 20px 0;
}
.pagination {
  text-align: center;
}
.content {
  display: flex;
  flex-wrap: wrap;
}
.content-item {
  /* width: 25%; */
  margin: 5px;
  cursor: pointer;
}
.content-title {
  color: #606266;
  font-size: 14px;
  font-weight: 500;
}
</style>
