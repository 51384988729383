import request from '@/utils/request'
/**
 * @param {object} data 
 */
export function getFormInfo (params) {
  return request({
    url: '/user/project/questionnaire/info',
    method: 'GET',
    params
  })
}
/**
 * @param {object} data 
 */
export function saveAnswers (data) {
  return request({
    url: '/user/project/questionnaire/save',
    method: 'POST',
    data
  })
}
/**
 * @param {object} data 
 */
export function getProjectVideos (params) {
  return request({
    url: '/user/project/videos',
    method: 'GET',
    params
  })
}
/**
 * @param {object} data 
 */
export function getProjectVideoInfo (params) {
  return request({
    url: '/user/project/video',
    method: 'GET',
    params
  })
}
/**
 * @param {object} data 
 */
export function getProjects (params) {
  return request({
    url: '/user/project/list',
    method: 'GET',
    params
  })
}
/**
 * @param {object} data 
 */
export function viewTime (data) {
  return request({
    url: '/user/project/video/view',
    method: 'POST',
    data
  })
}
/**
 * @param {object} data 
 */
export function clickVideo (data) {
  return request({
    url: '/user/project/video/click',
    method: 'POST',
    data
  })
}
/**
 * @param {object} data 
 */
export function clickVideoSend (data) {
  return request({
    url: '/user/project/video/click/send',
    method: 'POST',
    data
  })
}


/**
 * @param {object} data 
 */
export function getShareInfo (params) {
  return request({
    url: '/user/project/share/info',
    method: 'GET',
    params
  })
}