<template>
  <div class="box"
       id="box"
       oncontextmenu="return false">
    <video ref="videoPlayer"
           class="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'videojs-landscape-fullscreen'
import { viewTime, clickVideo, clickVideoSend } from '@/api/project'
import elementResizeDetectorMaker from 'element-resize-detector'
export default {
  props: {
    videoid: {
      type: Number,
      default () {
        return 0;
      }
    },
    watermask: {
      type: String,
      default () {
        return "";
      }
    },
    options: {
      type: Object,
      default () {
        return {};
      }
    }
  },
  data () {
    return {
      player: null,
      viewingTime: 0,
      view_id: 0,
      videoTime: 0,
      src: '',
      is_send: false,
    }
  },
  methods: {
    send () {
      //   console.log(this.player)
      if (this.player) {
        // console.log(this.viewingTime)
        if (this.viewingTime > 4) {
          viewTime({ view_id: this.view_id, video_time: this.videoTime, time: Number(this.viewingTime / 4) }).then(() => { this.viewingTime = 0 })
        }

        clickVideoSend({ view_id: this.view_id, video_time: this.videoTime, video_id: this.videoid }).then(() => { })
        this.player.dispose()
      }
    },
    textToImg (val) {
      if (!val) {
        return ''
      }
      var len = val.length;/*文字长度*/
      var i = 0;
      var fontSize = 24;/*文字大小*/
      var fontWeight = '900';/*normal正常;bold粗*/
      var txt = val;
      var canvas = document.createElement("canvas");
      if (len > txt.length) {
        len = txt.length;
      }
      canvas.width = (fontSize - 8.5) * len;
      canvas.height = fontSize * (3 / 2)
        * (Math.ceil(txt.length / len) + txt.split('\n').length - 1);
      var context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = 'rgba(150, 150, 150, 0.5)';/*颜色*/
      context.font = fontWeight + ' ' + fontSize + 'px 微软雅黑';
      context.textBaseline = 'top';
      canvas.style.display = 'flex';
      //console.log(txt.length);
      function fillTxt (text) {
        while (text.length > len) {
          var txtLine = text.substring(0, len);
          text = text.substring(len);
          context.fillText(txtLine, 0, fontSize * (3 / 2) * i++,
            canvas.width);
        }
        context.fillText(text, 0, fontSize * (3 / 2) * i, canvas.width);
      }
      var txtArray = txt.split('\n');
      for (var j = 0; j < txtArray.length; j++) {
        fillTxt(txtArray[j]);
        context.fillText('\n', 0, fontSize * (3 / 2) * i++, canvas.width);
      }
      //   var imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      context.getImageData(0, 0, canvas.width, canvas.height);
      //   console.log(imageData)
      //var img = $("img");
      //img.src = canvas.toDataURL("image/png");
      return canvas.toDataURL("image/png");
    }
  },
  mounted () {
    this.src = this.textToImg(this.watermask)
    let _this = this
    let oneTime = true
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady () {

      this.on('loadedmetadata', function () {
        _this.videoTime = this.duration();
      })
      this.on('timeupdate', function () {
        _this.viewingTime++
        // console.log(_this.viewingTime)
        if (oneTime) {
          oneTime = false
          clickVideo({ video_id: _this.videoid, video_time: _this.videoTime }).then((res) => {
            _this.view_id = res.data.id
          })
        }
      })
      this.on("pause", function () {
        viewTime({ view_id: _this.view_id, video_time: _this.videoTime, time: Number(_this.viewingTime / 4) }).then(() => { _this.viewingTime = 0 })
      });
      // this.on("ended", function () {
      //   if(!_this.is_send) {
      //     _this.is_send = true
      //     viewTime({ view_id: _this.view_id, video_time: _this.videoTime, time: Number(_this.viewingTime / 4) }).then(() => { _this.viewingTime = 0 })
      //   }
      // });
    })
    this.player.landscapeFullscreen({
      fullscreen: {
        enterOnRotate: true,
        alwaysInLandscapeMode: true,
        iOS: true
      }
    })

    this.$nextTick(() => {
      //   console.log(1)
      let dom = document.getElementById("box")
      dom = dom.firstChild
      const erd = elementResizeDetectorMaker()
      erd.listenTo(dom, (element) => {
        _this.$nextTick(() => {
          let wm = document.getElementById("box-email-watermask")
          let height = 0
          let width = 0
          // 横屏
          //   console.log(element.offsetWidth, element.offsetHeight)
          if (element.offsetWidth > element.offsetHeight) {
            width = Number(element.offsetHeight / 9 * 16)
            if (width > element.offsetWidth) {
              width = Number(element.offsetWidth) - 20
              height = Number(element.offsetWidth / 16 * 9) - 20
            } else {
              height = Number(element.offsetHeight) - 20
              width = width - 20
            }
          } else {
            height = Number(element.offsetWidth / 16 * 9)
            if (height > element.offsetHeight) {
              width = Number(element.offsetWidth) - 20
              height = Number(element.offsetWidth / 16 * 9) - 20
            } else {
              width = Number(element.offsetWidth) - 20
              height = height - 20
            }
          }
          let top = 'calc(50% - ' + height / 2 + 'px)'
          let right = 'calc(50% - ' + width / 2 + 'px)'
          // console.log("top", top, height)
          // console.log("right", right, width)
          wm.style.top = top
          wm.style.right = right
        })
      })
      let div = document.createElement('img');
      // div.innerHTML = this.watermask;
      div.src = this.src;
      div.id = 'box-email-watermask';
      dom.appendChild(div)
      //   console.log(dom)
    })

  },
  deactlvated () {
    // console.log("deactlvated")
  },
  beforeUnmount () {
    // console.log("beforeUnmount", this.player)
    this.send()
  }
}
</script>

<style scoped>
.box {
  min-width: 300px;
  position: relative;
}
.email {
}
</style>
<style>
.video-js .vjs-big-play-button {
  height: 3em;
  border-radius: 1.5em;
  line-height: 2.9em;
  top: calc(50% - 1.5em);
  left: calc(50% - 1.5em);
}

.video-js .vjs-big-play-button .vjs-icon-placeholder :before {
  font-size: 55px;
}
#box-email-watermask {
  position: absolute;
  top: 1.5%;
  right: 10px;
  z-index: 9999;
  color: rgba(200, 200, 200, 0.5);
  font-weight: 900;
  font-size: 1.5rem;
}
</style>