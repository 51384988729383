<template>
  <div v-if="!loading"
       class="container">
    <el-card class="register discrible"
             v-if="!isAnswer">
      <el-row v-if="logo"
              type="flex"
              justify="center"
              class="title">
        <el-image fit="contain"
                  style="height:80px;margin: 5px;"
                  :src="logo" />
      </el-row>
      <el-row type="flex"
              justify="center"
              class="title">Please fill out the questionnaire first</el-row>
      <el-form ref="quesForm"
               :model="quesform"
               label-width="100%"
               label-position="left"
               inline
               :rules="quesformRule">
        <el-form-item :label="item.key"
                      :prop="`qs-${item.id}`"
                      v-for="item in questions"
                      :key="`forms-${item.id}`">
          <el-input v-if="item.project_form_type_id === 1"
                    v-model="quesform[`qs-${item.id}`]"
                    size="mini"
                    placeholder="Please fill in"></el-input>
          <!-- :placeholder="`Please enter qs`"></el-input> -->

          <el-radio-group v-model="quesform[`qs-${item.id}`]"
                          v-if="item.project_form_type_id === 2">
            <el-radio :label="val"
                      v-for="(val, index) in item.value"
                      :key="`radio-${index}`">{{ val }}</el-radio>
          </el-radio-group>

          <el-checkbox-group v-model="quesform[`qs-${item.id}`]"
                             v-if="item.project_form_type_id === 3">
            <el-checkbox :label="val"
                         v-for="(val, index) in item.value"
                         :key="`checkbox-${index}`">{{ val }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-row type="flex"
                justify="end">
          <el-button size="mini"
                     type="primary"
                     @click="submitQues"
                     :loading="btnLoading">Next</el-button>
        </el-row>
      </el-form>
    </el-card>
    <template v-else>
      <Video v-if="isVideo"
             :projectId="projectId"
             :videoId="videoId"></Video>
      <project v-else
               :projectId="projectId"></project>
    </template>
  </div>
</template>

<script>
// https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ourwg4vgtmnn&redirect_uri=https://vision.asense.tech&state=foobar&scope=r_emailaddress
import Project from './project'
import Video from './video'
import { getShareInfo, getFormInfo, saveAnswers } from '@/api/project'
export default {
  components: {
    Project,
    Video
  },
  data () {
    return {
      loading: true,
      isVideo: false,
      projectId: 0,
      videoId: 0,
      isAnswer: false,
      info: {},
      questions: [],
      quesform: {},
      quesformRule: {},
      logo: '',
      btnLoading: false,
    }
  },
  inject: ["reload"],
  // watch: {
  //   $route () {
  //     if (this.$store.state.user.token) {
  //       this.getParams(this.$route.params.params)
  //     }
  //   },
  // },
  mounted () {
    // console.log("mounted")
    let query = this.$route.query
    if (query.token) {
      this.$store.dispatch('user/setToken', query.token).then(() => {
        this.$router.push('/' + this.$route.params.params)
      })
      // sessionStorage.setItem("Client-Token", query.token)
    }

    if (this.$store.state.user.token) {
      this.$store.dispatch('user/getInfo').then(() => {
        this.getParams(this.$route.params.params)
      }).catch(() => {
        this.loading = false
      })
    } else {
      this.$router.push('/?redirect=' + this.$route.params.params)
    }
  },
  methods: {
    init (data) {
      this.emailControl()
      this.projectId = Number(data.project_id)
      this.videoId = Number(data.video_id)
      if (this.videoId === 0 && this.$route.params.video > 0) {
        this.videoId = Number(this.$route.params.video)
      }
      //   console.log(this.videoId, this.videoId > 0)
      if (this.videoId > 0) {
        this.isVideo = true
      } else {
        this.isVideo = false
      }
      //   console.log(this.isVideo)
      //   this.loading = false
    },
    getParams (params) {
      this.isVideo = false
      let share = JSON.parse(sessionStorage.getItem(this.$route.params.params))
      if (share) {
        this.init(share)
        this.getFormInfo()
      } else {
        getShareInfo({ link: params }).then((res) => {
          this.info = res.data
          sessionStorage.setItem(this.$route.params.params, JSON.stringify(res.data))
          this.$store.dispatch('user/share', this.info).then(() => {
            this.init(res.data)
          })
          this.getFormInfo()
        }).catch((err) => {
          //   console.log(err.code)
          //   console.log(err.message, err.message == 'Share link has expired!')
          if (err.message === 'Share link has expired!') {
            this.$router.push('/?expired=1')
          } else {
            this.$router.push('/')
          }

          // this.$router.push('/?redirect=' + this.$route.params.params)
        })
      }

    },
    getFormInfo () {
      getFormInfo({ link: this.$route.params.params }).then(res => {
        this.logo = res.data.logo
        this.questions = res.data.forms.map(v => {
          const key = `qs-${v.id}`
          this.quesform[key] = ""
          //   this.quesformRule[key] = [
          //     { required: true, message: `Please complete the ${v.key} first`, trigger: 'blur' },
          //     { required: true, message: `Please complete the ${v.key} first`, trigger: 'change' }
          //   ]
          if (v.project_form_type_id === 1) {
            this.quesformRule[key] = [
              { required: true, message: `Please fill in`, trigger: 'blur' },
              { required: true, message: `Please fill in`, trigger: 'change' }
            ]
          } else {
            this.quesformRule[key] = [
              { required: true, message: `Please kindly select`, trigger: 'blur' },
              { required: true, message: `Please kindly select`, trigger: 'change' }
            ]
          }
          if (v.project_form_type_id === 2 || v.project_form_type_id === 3) {
            v.value = v.value.split(",")
            if (v.project_form_type_id === 3) {
              this.quesform[key] = []
            }
          }
          return v
        })
        if (res.data.answers.length <= 0) {
          this.isAnswer = false
        } else {
          if (this.$store.state.user.verify) {
            this.isAnswer = true
          } else {
            this.$router.push('/?send=1')
          }
        }
        // console.log("this.isAnswer", this.isAnswer)
        this.loading = false
      }).catch(() => {
        // console.log(err)
        this.$router.push('/?redirect=' + this.$route.params.params)
      })
    },
    emailControl () {
      //   console.log("emailControl")
      let email = sessionStorage.getItem("Client-Email")
      if (email) {
        let share = JSON.parse(sessionStorage.getItem(this.$route.params.params))
        if (share.email_suffix !== '') {
          let suffix = share.email_suffix.split(',')
          let control = true
          for (let i in suffix) {
            if (this.confirmEnding(email, suffix[i].trim())) {
              control = false
            }
          }
          //   console.log("control", control)
          if (control) {
            this.$router.push('/?control=1')
          }
        }
      }
    },
    confirmEnding (str, target) {
      var start = str.length - target.length;
      var arr = str.substr(start, target.length);
      if (arr == target) {
        return true;
      }
      return false;
    },
    submitQues () {
      if (this.btnLoading) {
        return
      }
      this.btnLoading = true
      let share = JSON.parse(sessionStorage.getItem(this.$route.params.params))
      if (!share) {
        this.$message.warning("Get share link failed!")
        this.btnLoading = false
        return
      }
      this.$refs['quesForm'].validate((valid) => {
        if (valid) {
          let form = {
            project_id: share.project_id,
            answers: []
          }
          for (let key in this.quesform) {
            const form_id = key.split("-")[1]
            let answer = this.quesform[key]
            if (Array.isArray(answer)) {
              answer = answer.join(",")
            }
            form.answers.push({
              form_id: form_id,
              answer: answer
            })
          }
          form.link = this.$route.params.params
          saveAnswers(form).then(() => {
            let isLinkedin = sessionStorage.getItem("Is-Linkedin")
            if (isLinkedin == 1) {
              this.$message.success("Submitted successfully!")
              this.reload()
            } else {
              this.$router.push('/?send=1')
            }
            this.btnLoading = false
          }).catch(() => {
            // console.log(err)
            this.btnLoading = false
          })
        } else {
          this.btnLoading = false
          return false
        }
      })
    },
  }
}
</script>
<style  scoped>
.container {
  min-height: 100%;
  width: 100%;
  background-color: #2d3a4b;
  overflow: hidden;
  background-image: url(~@/assets/bg.png);

  background-position: center center;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  margin-bottom: 20px;
}
.container /deep/ .el-form-item {
  width: 100%;
}
.container /deep/ .el-form--inline .el-form-item__label {
  line-height: 24px;
}
.register {
  /* margin-top: -100px; */
  width: 480px;
  max-height: 90%;
  overflow-y: auto;
}
.discrible {
  overflow: scroll;
}
/*整个滚动条样式*/
.discrible::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}
/*设置滚动条上的滚动滑块样式*/
.discrible::-webkit-scrollbar-thumb {
  background: transparent;
}
/*鼠标位于内容上时，滚动条样式*/
.discrible:hover::-webkit-scrollbar {
  width: 6px;
  height: 0px;
  background: rgba(238, 238, 238, 1);
  border-radius: 6px;
}
/*鼠标位于内容上时，滚动滑块样式*/
.discrible:hover::-webkit-scrollbar-thumb {
  background: #9bcaff;
  border-radius: 10px;
}
</style>