<template>
  <div class="container"
       v-loading="loading">
    <el-card class="videos">

      <el-row type="flex"
              align="middle">
        <el-col :span="2">
          <el-row justify="start"
                  type="flex">
            <el-button icon="el-icon-arrow-left"
                       type="text"
                       @click="goback">Back</el-button>
          </el-row>
        </el-col>
        <el-col :span="20">
          <el-row justify="center"
                  type="flex">
            <div class="title">
              {{ video.title }}
            </div>
          </el-row>
        </el-col>
      </el-row>
      <!-- <el-row justify="center"
              type="flex">
        <div class="title">
          {{ video.title }}
        </div>
      </el-row> -->
      <div class="content">
        <video-player v-if="!loading"
                      :videoid="video_id"
                      :watermask="info.email"
                      :options="videoOptions" />
      </div>
      <div class="info">
        <el-row>
          <div class="description">
            {{ video.description }}
          </div>
        </el-row>
        <el-row justify="end"
                type="flex">
          <div class="time">
            {{ video.updated_at }}
          </div>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive } from "vue";
import VideoPlayer from "@/components/VideoPlayer";
import { getProjectVideoInfo } from '@/api/project'
// const routeVKey = 'vi:'
export default {
  components: {
    VideoPlayer
  },
  props: {
    projectId: {
      type: Number,
      default () {
        return 0;
      }
    },
    videoId: {
      type: Number,
      default () {
        return 0;
      }
    },
  },
  data () {
    return {
      loading: true,
      project_id: 0,
      video_id: 0,
      video: {},
      info: {},
      share: {},
    }
  },
  inject: ["reload"],
  setup () {
    const videoOptions = reactive({
      controls: true,//用户可以与之交互的控件
      loop: false,//视频一结束就重新开始
      muted: false,//默认情况下将使所有音频静音
      aspectRatio: "16:9",//显示比率
      fullscreen: {
        options: { navigationUI: 'hide' }
      },
      playbackRates: [0.5, 1, 1.25, 1.5, 2, 3],
      controlBar: {
        pictureInPictureToggle: false,
        fullscreenToggle: true
      },
      sources: [
        {
          src: '',
          type: "video/mp4"
        }
      ]
    });
    return {
      videoOptions
    }
  },
  mounted () {
    let redirect = "?redirect=/" + this.$route.params.params
    let share = JSON.parse(sessionStorage.getItem(this.$route.params.params))
    if (this.$route.params.video > 0) {
      redirect += '/' + this.$route.params.video
    }

    this.project_id = this.projectId
    this.video_id = this.videoId
    if (this.$store.getters.token) {
      this.$store.dispatch('user/getInfo').then(res => {
        this.info = res.data
      }).catch(() => {
        this.$router.push('/' + this.$route.params.params + redirect)
      })
      getProjectVideoInfo({ form_id: share.form_id, video_id: this.video_id }).then(res => {

        this.video = res.data
        if (!this.videoOptions.sources) {
          this.videoOptions.sources = []
        }
        if (this.videoOptions.sources.length > 0) {
          this.videoOptions.sources[0].src = this.video.video_url
        } else {
          this.videoOptions.sources = [
            {
              src: this.video.video_url,
              type: "video/mp4"
            }
          ]
        }
        this.loading = false
      }).catch(() => {
        this.$router.push('/' + this.$route.params.params + redirect)
      })
    } else {
      this.$router.push('/' + this.$route.params.params + redirect)
    }
  },
  methods: {
    goback () {
      let time = new Date().getTime()
      this.$router.push('/' + this.$route.params.params + "?t=" + time)
      this.reload()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.container {
  min-height: 100%;
  width: 100%;
  background-color: #2d3a4b;
  overflow: hidden;
  background-image: url(~@/assets/bg.png);

  background-position: center center;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}
.videos {
  min-width: 70%;
}
.title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #303133;
}
.description {
  font-size: 18px;
  padding: 5px 0;
  color: #303133;
}
.time {
  font-size: 16;
  color: #909399;
}
</style>
